import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import type { NextPage } from 'next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../shared/providers/auth/authProvider';
import { auth } from '../shared/utils/firebase';
import {
  getPawblisherRedirectUrl,
  getRedirectUrl,
  isPawblisherRedirect,
  RedirectApp,
} from '../shared/utils/getRedirectUrl';
import * as Sentry from '@sentry/nextjs';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Box, Flex, VStack } from '@televet/kibble-ui/build/chakra';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Logo } from '@televet/kibble-ui/build/components/Logo';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { useToast } from '@televet/kibble-ui/build/components/Toast';

type FormData = {
  email: string;
  password: string;
};

enum FormState {
  Login = 'Log In',
  ForgotPassword = 'Forgot Password',
}

const emailTest = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const Login: NextPage = () => {
  const toast = useToast();
  const [formState, setFormState] = useState(FormState.Login);
  const { currentClusterUser, clusterUsers, customToken, login, loading } = useAuth();
  const router = useRouter();
  const redirectApp = router.query.redirectApp;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>({
    mode: 'onBlur',
  });

  useEffect(() => {
    const redirect = getRedirectUrl();
    if (isPawblisherRedirect() && customToken) {
      auth.currentUser?.getIdToken(true).then((idToken) => {
        const url = getPawblisherRedirectUrl(idToken);
        window.location.href = url;
      });
    } else {
      if (currentClusterUser) {
        window.location.href = `${currentClusterUser.cluster.flowUrl}/tokenlogin/${customToken}${
          redirect ? `?${redirect}` : ''
        }`;
      }
      if (clusterUsers.length > 1) {
        router.push(`/select-clinic${redirect ? `?${redirect}` : ''}`);
      }
    }
  }, [currentClusterUser, customToken, clusterUsers, router]);

  const handleErrorMessages = (error: { code: string; message: string }): void => {
    const errorCode = error.code;
    const errorMessage = error.message;

    if (errorCode === 'auth/wrong-password') {
      setError('password', { message: 'Password Invalid' });
    } else {
      setError('email', { message: errorMessage });
    }
  };

  const checkLoginInfo = async ({ email, password }: { email: string; password: string }): Promise<void> => {
    if (!password) {
      setError('password', { message: 'Password Required' });
    }
    if (email && password) {
      await login(email, password).catch((error) => {
        handleErrorMessages(error);
        Sentry.captureException(error);
      });
    }
  };

  const checkForgotPasswordInfo = async (email: string): Promise<boolean> => {
    let isValid = true;
    if (email) {
      await sendPasswordResetEmail(auth, email).catch((error) => {
        handleErrorMessages(error);
        isValid = false;
      });
      return isValid;
    }

    return false;
  };

  const onSubmit = async ({ email, password }: FormData): Promise<void> => {
    if (!email) {
      setError('email', { message: 'Email Required' });
      return;
    }

    if (email && !emailTest.test(email)) {
      setError('email', { message: 'Invalid Email' });
      return;
    }

    if (formState == FormState.Login) {
      await checkLoginInfo({ email, password });
    }

    if (formState == FormState.ForgotPassword) {
      const results = await checkForgotPasswordInfo(email);

      if (!results) return;

      toast({
        title: 'Instructions Sent!',
        description: `Check your ${email} inbox for an email containing a link to reset your Otto password. Keep in mind that it may take a minute or two to reach you.`,
        duration: 20000,
      });
    }
  };

  const toggleFormState = (): void => {
    reset();
    setFormState(formState === FormState.ForgotPassword ? FormState.Login : FormState.ForgotPassword);
  };

  const appName = useMemo(() => (typeof redirectApp === 'string' ? redirectApp : RedirectApp.Flow), [redirectApp]);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} maxH="-webkit-fill-available" py={{ base: 5, md: 0 }}>
      <VStack spacing={4} overflowY="auto">
        <Box width="320px" mb={4}>
          <Heading size="xl" textTransform="capitalize">
            {appName}
          </Heading>
          <Flex mt={1}>
            <Text textTransform="uppercase" size="xs" variant="subtle" mr={1}>
              Powered By
            </Text>
            <Logo w={10} />
          </Flex>
        </Box>
        <Box width="320px" mb={6}>
          <Heading size="md" mb={2}>
            {formState}
          </Heading>
          {formState === FormState.ForgotPassword && (
            <Text mt={3}>
              Provide your email below, and we&apos;ll send instructions to reset your password if it&apos;s associated
              with an existing Otto account.
            </Text>
          )}
        </Box>
        <Box width="320px">
          <TextInput
            type="email"
            errorText={errors?.email?.message || 'This field is invalid'}
            placeholder="Email"
            isInvalid={Boolean(errors.email)}
            {...(formState == FormState.Login && { label: 'Email' })}
            {...register('email')}
          />
        </Box>
        {formState == FormState.Login && (
          <Box width="320px">
            <TextInput
              onKeyUp={(e): void => {
                if (e.getModifierState('CapsLock')) {
                  setError('password', { message: 'Caps Lock is ON' });
                } else {
                  clearErrors('password');
                }
              }}
              type="password"
              placeholder="Password"
              errorText={errors?.password?.message}
              isInvalid={Boolean(errors.password)}
              label="Password"
              {...register('password')}
            />
          </Box>
        )}
        <Box width="320px" pt={2}>
          <Flex flexDirection="column">
            <Button isLoading={loading} type="submit">
              {formState == FormState.Login ? 'Log In' : 'Submit'}
            </Button>
            <Button variant="ghost" onClick={toggleFormState} my={1}>
              {formState == FormState.Login ? 'Forgot Password?' : 'Return to Log In'}
            </Button>
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default Login;
